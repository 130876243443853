export const navLinks = [
  {
    id: 1,
    to: "/",
    title: "Home",
  },
  {
    id: 2,
    to: "/company",
    title: "Company",
  },
  {
    id: 3,
    to: "/services",
    title: "Services",
  },
  {
    id: 4,
    to: "/gallery",
    title: "Gallery",
  },
  {
    id: 5,
    to: "/contact",
    title: "Contact",
  },
];
