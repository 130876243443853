export const links = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/company",
    name: "Company",
  },
  {
    id: 3,
    path: "/services",
    name: "Services",
  },
  {
    id: 4,
    path: "/gallery",
    name: "Gallery",
  },
  {
    id: 5,
    path: "/contact",
    name: "Contact",
  },
];
