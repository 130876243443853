import image1 from "../assets/gallery/IMG_0811.png";
import image2 from "../assets/gallery/IMG_0825.png";
import image3 from "../assets/gallery/IMG_0827.png";
import image4 from "../assets/gallery/IMG_0828.png";
import image5 from "../assets/gallery/IMG_3299.png";
import image6 from "../assets/gallery/IMG_3348.png";
import image7 from "../assets/gallery/IMG_3350.png";
import image8 from "../assets/gallery/IMG_2619.png";
import image9 from "../assets/gallery/IMG_2617.png";
import image10 from "../assets/gallery/IMG_1141.png";

export default [
  {
    urls: image2,
  },
  {
    urls: image1,
  },
  {
    urls: image3,
  },
  {
    urls: image9,
  },
  {
    urls: image8,
  },
  {
    urls: image4,
  },
];
